import React from "react";
import logo from '../logo.svg';
import 'normalize.css';
import '../css/home.css'

function Home() {
   return (
      <div className="ct" id="t1">
         <div className="ct" id="t2">
            <div className="ct" id="t3">
               <div className="ct" id="t4">
                  <div className="ct" id="t5">
                  <ul id="menu">
                     <a href="#t1"><li className="icon fab fa-atlassian" id="uno"></li></a>
                     <a href="#t2"><li className="icon fa fa-id-badge" id="dos"></li></a>
                     <a href="#t3"><li className="icon fab fa-github" id="tres"></li></a>
                     <a href="#t4"><li className="icon fa fa-project-diagram" id="cuatro"></li></a>
                     <a href="#t5"><li className="icon fa fa-plus-circle" id="cinco"></li></a>
                  </ul>
                  <div className="page" id="p1">
                     <section className="icon fab fa-atlassian">
                        <span className="title">Hey</span>
                        <span className="hint">  User <br/> Go and check My Projects</span>
                     </section>  
                  </div>
                  <div className="page" id="p2">
                     <section className="icon fa fa-id-badge"><span className="title">About me</span></section>
                  </div>  
                  <div className="page" id="p3">
                     <section className="icon fa fa-rocket"><span className="title">Visit My Github profile</span></section>
                  </div>
                  <div className="page" id="p4">
                     <section className="icon fa fa-project-diagram">
                     <span className="title">Projects</span>
                     <p className="hint">
                        <a href="https://dribbble.com/albertohartzet" target="_blank" rel="noreferrer">Currently i Don't Have project </a>
                     </p>
                     </section>
                  </div> 
                  <div className="page" id="p5">
                     <section className="icon fa fa-plus-circle">
                     <span className="title">More</span>
                     <p className="hint">
                        <span>Wants More?</span><br/>
                        <a href="https://github.com/rdhksnvns" target="_blank">check My Github Profile</a>
                     </p>
                     </section>
                  </div> 
               </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Home;